import { Injectable } from '@angular/core';
import { IResourceItem, ApiFactoryService, ExportApi } from 'swx.front-end-lib';
import { Airport } from "./model/Airport";
import { MetarWeatherTypeSummary } from "./model/MetarWeatherTypeSummary";
import { MetarWeatherMappingProfile } from "./model/MetarWeatherMappingProfile";
import { HotSummary } from "./model/HotSummary";
import { Client } from "./model/Client";

export interface Fluid extends IResourceItem {
	Id: number;
	FluidVersionId: number;
	ClientFluidName: string;
	Order: number;
	Name: string;
	Dilution: string;
	Type: string;
	WingMaterialType: string;
	FluidTypeNumber: number;
	ClientId: number;
	Airports: Array<number>;
    FluidVersions: Array<any>;
}

@Injectable({ providedIn: 'root' })
export class ApiService implements ExportApi {
    constructor(
        private apiFactory: ApiFactoryService,
    ) {
    }

    // enums
    Permission = this.apiFactory.enumResourceFactory('/Permission');
    SubjectType = this.apiFactory.enumResourceFactory('/SubjectType');
    MobileAppPlatform = this.apiFactory.enumResourceFactory('/MobileAppPlatform');
    MobileAppAudience = this.apiFactory.enumResourceFactory('/MobileAppAudience');
    MetarWarning = this.apiFactory.enumResourceFactory('/MetarWarning');
    WeatherType = this.apiFactory.enumResourceFactory('/WeatherType');
    WeatherTypeNg = this.apiFactory.enumResourceFactory('/WeatherTypeNg');
    WeatherTypeSensorType = this.apiFactory.enumResourceFactory('/WeatherTypeSensorType');
    WeatherTypeNgFlags = this.apiFactory.enumResourceFactory('/WeatherTypeNgFlags');
    WeatherCategory = this.apiFactory.enumResourceFactory('/WeatherCategory');
    SensorType = this.apiFactory.enumResourceFactory('/SensorType');
    AcarsPriority = this.apiFactory.enumResourceFactory('/AcarsPriority');
    StationLoggerType = this.apiFactory.enumResourceFactory('/StationLoggerType');
    StationType = this.apiFactory.enumResourceFactory('/StationType');
    StationProblemType = this.apiFactory.enumResourceFactory('/StationProblemType');
    OTTPluvio2HeaterStatus = this.apiFactory.enumResourceFactory('/OTTPluvio2HeaterStatus');
    OTTPluvio2SensorStatus = this.apiFactory.enumResourceFactory('/OTTPluvio2SensorStatus');
    SensorReadingVaisalaWMT700Status = this.apiFactory.enumResourceFactory('/SensorReadingVaisalaWMT700Status');
    LWESource = this.apiFactory.enumResourceFactory('/LWESource');
    WeatherCategorySource = this.apiFactory.enumResourceFactory('/WeatherCategorySource');
    InstantWeatherTypeAlgorithm = this.apiFactory.enumResourceFactory('/InstantWeatherTypeAlgorithm');
    WingMaterialType = this.apiFactory.enumResourceFactory('/WingMaterialType');
    WeatherProfileError = this.apiFactory.enumResourceFactory('/WeatherProfileError');
    HotRequestType = this.apiFactory.enumResourceFactory('/HotRequestType');
    HotResponseType = this.apiFactory.enumResourceFactory('/HotResponseType');
    DeicingRecordError = this.apiFactory.enumResourceFactory('/DeicingRecordError');
    DeicingRecordFormat = this.apiFactory.enumResourceFactory('/DeicingRecordFormat');
    Month = this.apiFactory.enumResourceFactory('/Month');
    StationProblemLevel = this.apiFactory.enumResourceFactory('/StationProblemLevel');
    MetarWeatherType = this.apiFactory.enumResourceFactory('/MetarWeatherType');
    RegulatoryAgency = this.apiFactory.enumResourceFactory('/RegulatoryAgency');
    MobileSnowVisibilityMenu = this.apiFactory.enumResourceFactory('/MobileSnowVisibilityMenu');
    ClientWeatherType = this.apiFactory.enumResourceFactory('/ClientWeatherType');
    WeatherWarning = this.apiFactory.enumResourceFactory('/WeatherWarning');
    FluidType = this.apiFactory.enumResourceFactory('/FluidType');
    RemoteEndpointType = this.apiFactory.enumResourceFactory('/RemoteEndpointType');
    RemoteEndpointFormat = this.apiFactory.enumResourceFactory('/RemoteEndpointFormat');
    FluidTypeFlags = this.apiFactory.enumResourceFactory('/FluidTypeFlags');
    LWEMessageMappingHotType = this.apiFactory.enumResourceFactory('/LWEMessageMappingHotType');
    SartoriusAlarm = this.apiFactory.enumResourceFactory('/SartoriusAlarm');
    SartoriusDigitalInput = this.apiFactory.enumResourceFactory('/SartoriusDigitalInput');
    SartoriusDigitalOutput = this.apiFactory.enumResourceFactory('/SartoriusDigitalOutput');
    MetarSource = this.apiFactory.enumResourceFactory('/MetarSource');
    MetarReadingError = this.apiFactory.enumResourceFactory('/MetarReadingError');
    EngineCoverAlertType = this.apiFactory.enumResourceFactory('/EngineCoverAlertType');
    DatalinkServiceProvider = this.apiFactory.enumResourceFactory('/DatalinkServiceProvider');
    FreezingFogWeatherSource = this.apiFactory.enumResourceFactory('/FreezingFogWeatherSource');
    AcarsHotLabel = this.apiFactory.enumResourceFactory('/AcarsHotLabel');
    SensorReadingPondHotPlateSystemStatus = this.apiFactory.enumResourceFactory('/SensorReadingPondHotPlateSystemStatus');
    AcarsOooiEventCode = this.apiFactory.enumResourceFactory('/AcarsOooiEventCode');
    ClientWeatherTypeFlags = this.apiFactory.enumResourceFactory('/ClientWeatherTypeFlags');
    ActiveFrostTime = this.apiFactory.enumResourceFactory('/ActiveFrostTime');
    CampbellPWS100LaserControlMode = this.apiFactory.enumResourceFactory('/CampbellPWS100LaserControlMode');
    CampbellPWS100FaultStatus = this.apiFactory.enumResourceFactory('/CampbellPWS100FaultStatus');

    IBMMQServerConfigurationType = this.apiFactory.enumResourceFactory('/ibmMqServerConfigurationType');
    IBMMQServerConnectionMethod = this.apiFactory.enumResourceFactory('/ibmMqServerConnectionMethod');
    IMAPServerConfigurationType = this.apiFactory.enumResourceFactory('/imapServerConfigurationType');
    IMAPSecureSocketOption = this.apiFactory.enumResourceFactory('/imapSecureSocketOption');
    IMAPProcessOption = this.apiFactory.enumResourceFactory('/imapProcessOption');
    JMSServerConfigurationType = this.apiFactory.enumResourceFactory('/jmsServerConfigurationType');
    SQSQueueConfigurationType = this.apiFactory.enumResourceFactory('/sqsQueueConfigurationType');
    SFTPServerConfigurationType = this.apiFactory.enumResourceFactory('/sftpServerConfigurationType');
    FluidDilutionFormat = this.apiFactory.enumResourceFactory('/FluidDilutionFormat');
    FluidHotSpecWeatherType = this.apiFactory.enumResourceFactory('/FluidHotSpecWeatherType');
    MobileMetarVisibility = this.apiFactory.enumResourceFactory('/mobileMetarVisibility');
    RoundingMethod = this.apiFactory.enumResourceFactory('/roundingMethod');
    PortalUserPermission = this.apiFactory.enumResourceFactory('/portalUserPermission');
    DeicingRequestServiceType = this.apiFactory.enumResourceFactory('/deicingRequestServiceType');
    DeicingResponseStatusOption = this.apiFactory.enumResourceFactory('/DeicingResponseStatusOption');
    ClientContactType = this.apiFactory.enumResourceFactory('/clientContactType');
    ClientContactAirportWarningType = this.apiFactory.enumResourceFactory('/clientContactAirportWarningType');
    AcarsTo = this.apiFactory.enumResourceFactory('/acarsTo');
    MobileExternalInput = this.apiFactory.enumResourceFactory('/mobileExternalInput');
    MobileTimerTimeZoneOption = this.apiFactory.enumResourceFactory('/mobileTimerTimeZoneOption');
    DashboardGroup = this.apiFactory.enumResourceFactory('/DashboardGroup');
    ServiceLevelLogo = this.apiFactory.enumResourceFactory('/ServiceLevelLogo');
    OTTParsivel2SensorStatus = this.apiFactory.enumResourceFactory('/OTTParsivel2SensorStatus');
    MQTTAuthenticationType = this.apiFactory.enumResourceFactory('/MQTTAuthenticationType');
    
    // resources
    AcarsTemplate = this.apiFactory.resourceFactory<any>('/AcarsTemplate');
    User = this.apiFactory.resourceFactory<any>('/User');
    UserExport = this.apiFactory.resourceFactory<any>('/UserExport');
    Change = this.apiFactory.resourceFactory<any>('/Change');
    ChangeExport = this.apiFactory.resourceFactory<any>('/ChangeExport');
    ChangeAlert = this.apiFactory.resourceFactory<any>('/ChangeAlert');
    UserAuthentication = this.apiFactory.resourceFactory<any>('/UserAuthentication');
    UserAuthenticationExport = this.apiFactory.resourceFactory<any>('/UserAuthenticationExport');
    SamlLoginRequest = this.apiFactory.resourceFactory<any>('/Saml/LoginRequest');
    SamlLogoutRequest = this.apiFactory.resourceFactory<any>('/Saml/LogoutRequest');
    ExcelExportTask = this.apiFactory.resourceFactory<any>('/ExcelExportTask');
    ExcelExportResult = this.apiFactory.resourceFactory<any>('/ExcelExportResult');
    ClientApiToken = this.apiFactory.resourceFactory<any>('/ClientApiToken');
    ClientApiTokenExport = this.apiFactory.resourceFactory<any>('/ClientApiTokenExport');
    MobileApp = this.apiFactory.resourceFactory<any>('/MobileApp');
    MobileAppExport = this.apiFactory.resourceFactory<any>('/MobileAppExport');
    MobileAppRelease = this.apiFactory.resourceFactory<any>('/MobileAppRelease');
    MobileAppReleaseExport = this.apiFactory.resourceFactory<any>('/MobileAppReleaseExport');
    MobileWeatherMappingProfile = this.apiFactory.resourceFactory<any>('/MobileWeatherMappingProfile');
    MobileWeatherMappingProfileExport = this.apiFactory.resourceFactory<any>('/MobileWeatherMappingProfileExport');
    FileUpload = this.apiFactory.resourceFactory<any>('/FileUpload');
    FileUploadUrl = this.apiFactory.resourceFactory<any>('/FileUploadUrl');
    Station = this.apiFactory.resourceFactory<any>('/Station');
    StationDetail = this.apiFactory.resourceFactory('/StationDetail');
    StationDetailExport = this.apiFactory.resourceFactory('/StationDetailExport');
    StationProblemConfigurationProfile = this.apiFactory.resourceFactory<any>('/StationProblemConfigurationProfile');
    StationProblemConfigurationProfileExport = this.apiFactory.resourceFactory('/StationProblemConfigurationProfileExport');
    StationProblem = this.apiFactory.resourceFactory('/StationProblem');
    StationProblemExport = this.apiFactory.resourceFactory('/StationProblemExport');
    SensorSelectMatrix = this.apiFactory.resourceFactory<any>('/SensorSelectMatrix');
    SensorSelectMatrixExport = this.apiFactory.resourceFactory('/SensorSelectMatrixExport');
    SensorSelectMatrixSimulation = this.apiFactory.resourceFactory('/SensorSelectMatrixSimulation');
    SensorSelectMatrixGen2 = this.apiFactory.resourceFactory('/SensorSelectMatrixGen2');
    SensorSelectMatrixGen3 = this.apiFactory.resourceFactory('/SensorSelectMatrixGen3');
    SensorSelectMatrixGenV = this.apiFactory.resourceFactory('/SensorSelectMatrixGenV');
    WeatherProfile = this.apiFactory.resourceFactory('/WeatherProfile');
    DebugWeatherProfile = this.apiFactory.resourceFactory('/DebugWeatherProfile');
    Fluid = this.apiFactory.resourceFactory<any>('/Fluid');
    FluidDetail = this.apiFactory.resourceFactory<any>('/FluidDetail');
    FluidDetailExport = this.apiFactory.resourceFactory<any>('/FluidDetailExport');
    FluidVersion = this.apiFactory.resourceFactory<any>('/FluidVersion');
    FluidSeason = this.apiFactory.resourceFactory<any>('/FluidSeason');
    HotCalculation = this.apiFactory.resourceFactory('/hotCalculation');
    HotRequest = this.apiFactory.resourceFactory('/HotRequest');
    DebugHotRequest = this.apiFactory.resourceFactory('/DebugHotRequest');
    ClientDetail = this.apiFactory.resourceFactory<any>('/ClientDetail');
    ClientDetailExport = this.apiFactory.resourceFactory<any>('/ClientDetailExport');
    ClientContact = this.apiFactory.resourceFactory<any>('/ClientContact');
    ClientPortalUser = this.apiFactory.resourceFactory<any>('/ClientPortalUser');
    Client = this.apiFactory.resourceFactory<Client>('/client');
    Airport = this.apiFactory.resourceFactory<Airport>('/airport');
    AirportExport = this.apiFactory.resourceFactory('/airportExport');
    AirportCountry = this.apiFactory.resourceFactory('/airportCountry');
    AirportRegion = this.apiFactory.resourceFactory('/airportRegion');
    EquipmentType = this.apiFactory.resourceFactory<any>('/equipmentType');
    EquipmentTypeExport = this.apiFactory.resourceFactory('/equipmentTypeExport');
    HotSimulation = this.apiFactory.resourceFactory('/hotSimulation');
    SensorDecode = this.apiFactory.resourceFactory('/sensorDecode');
    StationDecode = this.apiFactory.resourceFactory('/stationDecode');
    MetarFinalType = this.apiFactory.resourceFactory('/metarFinalType');
    SunCalculator = this.apiFactory.resourceFactory('/sunCalculator');
    Log = this.apiFactory.resourceFactory('/log');
    ClientApi = this.apiFactory.resourceFactory('/clientApi');
    AcarsIncomingMessageDecoder = this.apiFactory.resourceFactory('/acarsIncomingMessageDecoder');
    AcarsRequestProcessor = this.apiFactory.resourceFactory('/acarsRequestProcessor');
    XmlRequest = this.apiFactory.resourceFactory('/xmlRequest');
    IBMMQServerConfiguration = this.apiFactory.resourceFactory<any>('/ibmMqServerConfiguration');
    IBMMQTest = this.apiFactory.resourceFactory('/IBMMQTest');
    MQTTBroker = this.apiFactory.resourceFactory<any>('/MQTTBroker');
    EventTest = this.apiFactory.resourceFactory('/eventTest');
    StationReadingBuffer = this.apiFactory.resourceFactory('/stationReadingBuffer');
    StationReadingDataWindow = this.apiFactory.resourceFactory('/stationReadingDataWindow');
    DataCollectorStationReadingBuffer = this.apiFactory.resourceFactory('/dataCollectorStationReadingBuffer');
    DataCollectorWeatherProfileBuffer = this.apiFactory.resourceFactory('/dataCollectorWeatherProfileBuffer');
    WeatherProfileRerun = this.apiFactory.resourceFactory('/WeatherProfileRerun');
    WeatherProfileRerunTask = this.apiFactory.resourceFactory('/WeatherProfileRerunTask');
    StationReadingImport = this.apiFactory.resourceFactory('/StationReadingImport');
    StationReadingImportTask = this.apiFactory.resourceFactory('/StationReadingImportTask');
    WorkspaceOne = this.apiFactory.resourceFactory<any>('/WorkspaceOne');
    WorkspaceOneGroup = this.apiFactory.resourceFactory<any>('/WorkspaceOneGroup');
    WorkspaceOneSync = this.apiFactory.resourceFactory<any>('/WorkspaceOneSync');
    WorkspaceOneSyncAll = this.apiFactory.resourceFactory<any>('/WorkspaceOneSyncAll');
    MetarValidation = this.apiFactory.resourceFactory('/MetarValidation');
    LWEMessageMappingProfile = this.apiFactory.resourceFactory<any>('/LWEMessageMappingProfile');
    Gen2Validation = this.apiFactory.resourceFactory('/gen2Validation');
    Gen3Validation = this.apiFactory.resourceFactory('/gen3Validation');
    Config = this.apiFactory.resourceFactory<any>('/Config');
    MetarReadingDataUpload = this.apiFactory.resourceFactory('/metarReadingDataUpload');
    HumanObservationDataUpload = this.apiFactory.resourceFactory('/humanObservationDataUpload');
    StationAlertEmail = this.apiFactory.resourceFactory('/StationAlertEmail');
    StationAlertEmailExport = this.apiFactory.resourceFactory('/StationAlertEmailExport');
    StationAlertTemplate = this.apiFactory.resourceFactory('/StationAlertTemplate');
    StationStatus = this.apiFactory.resourceFactory<any>('/StationStatus');
    StationSureLoggerSerialPortState = this.apiFactory.resourceFactory('/StationSureLoggerSerialPortState');
    StationSureLoggerSerialCommand = this.apiFactory.resourceFactory('/StationSureLoggerSerialCommand');
    StationSureLoggerSensorPowerState = this.apiFactory.resourceFactory('/StationSureLoggerSensorPowerState');
    StationSureLoggerStatus = this.apiFactory.resourceFactory('/StationSureLoggerStatus');
    StationSureLoggerLog = this.apiFactory.resourceFactory('/StationSureLoggerLog');
    StationSureLoggerConfiguration = this.apiFactory.resourceFactory('/StationSureLoggerConfiguration');
    StationSureLoggerSerialMaintenanceMode = this.apiFactory.resourceFactory('/StationSureLoggerSerialMaintenanceMode');
    WeatherTypeMappingProfile = this.apiFactory.resourceFactory<any>('/WeatherTypeMappingProfile');
    WeatherTypeMappingProfileExport = this.apiFactory.resourceFactory('/WeatherTypeMappingProfileExport');
    LWEEquationProfile = this.apiFactory.resourceFactory<any>('/LWEEquationProfile');
    LWEEquationProfileExport = this.apiFactory.resourceFactory<any>('/LWEEquationProfileExport');
    WeatherTypeCountProfile = this.apiFactory.resourceFactory<any>('/WeatherTypeCountProfile');
    WeatherTypeNgToCategoryMappingProfile = this.apiFactory.resourceFactory<any>('/WeatherTypeNgToCategoryMappingProfile');
    InstantWeatherTypeRuleProfile = this.apiFactory.resourceFactory<any>('/InstantWeatherTypeRuleProfile');
    RemoteEndpoint = this.apiFactory.resourceFactory<any>('/RemoteEndpoint');
    ThiesLPMWMOCodeMappingProfile = this.apiFactory.resourceFactory<any>('/ThiesLPMWMOCodeMappingProfile');
    MetarSetting = this.apiFactory.resourceFactory<any>('/MetarSetting');
    MetarWeatherMappingProfile = this.apiFactory.resourceFactory<MetarWeatherMappingProfile>('/MetarWeatherMappingProfile');
    MetarWeatherMappingProfileExport = this.apiFactory.resourceFactory<any>('/MetarWeatherMappingProfileExport');
    MetarMessageMappingProfile = this.apiFactory.resourceFactory<any>('/MetarMessageMappingProfile');
    MetarMessageMappingProfileDetailExport = this.apiFactory.resourceFactory<any>('/MetarMessageMappingProfileDetailExport');
    MetarMixedPrecipitationProfile = this.apiFactory.resourceFactory<any>('/MetarMixedPrecipitationProfile');
    MetarWeatherCodeMappingProfile = this.apiFactory.resourceFactory<any>('/MetarWeatherCodeMappingProfile');
    MetarWeatherCodeMappingProfileExport = this.apiFactory.resourceFactory<any>('/MetarWeatherCodeMappingProfileExport');
    MetarWeatherCodeRemappingProfile = this.apiFactory.resourceFactory<any>('/MetarWeatherCodeRemappingProfile');
    MetarWeatherTypeMappingProfile = this.apiFactory.resourceFactory<any>('/MetarWeatherTypeMappingProfile');
    MetarWeatherTypeMappingProfileExport = this.apiFactory.resourceFactory<any>('/MetarWeatherTypeMappingProfileExport');
    HumanObservationWeatherProfile = this.apiFactory.resourceFactory('/humanObservationWeatherProfile');
    HumanObservationWeatherProfileExport = this.apiFactory.resourceFactory('/humanObservationWeatherProfileExport');
    DeicingHot = this.apiFactory.resourceFactory('/deicingHot');
    DeicingHotExport = this.apiFactory.resourceFactory('/deicingHotExport');
    DeicingRecordDataUpload = this.apiFactory.resourceFactory('/deicingRecordDataUpload');
    DeicingHotSummary = this.apiFactory.resourceFactory('/deicingHotSummary');
    MetarWeatherTypeSummary = this.apiFactory.resourceFactory<MetarWeatherTypeSummary>('/metarWeatherTypeSummary');
    HotSummary = this.apiFactory.resourceFactory<HotSummary>('/hotSummary');
    HotCondition = this.apiFactory.resourceFactory<any>('/HotCondition');
    StationMonitoringProfile = this.apiFactory.resourceFactory<any>('/StationMonitoringProfile');
    StationMonitoringAlert = this.apiFactory.resourceFactory<any>('/StationMonitoringAlert');
    StationMonitoringAlertExport = this.apiFactory.resourceFactory<any>('/StationMonitoringAlertExport');
    Hot = this.apiFactory.resourceFactory('/hotRequest');
    HotExport = this.apiFactory.resourceFactory('/hotRequestExport');
    HistoricHot = this.apiFactory.resourceFactory('/HistoricHot');
    HistoricHotExport = this.apiFactory.resourceFactory('/HistoricHotExport');
    DeicingReport = this.apiFactory.resourceFactory('/deicingReport');
    DeicingReportExport = this.apiFactory.resourceFactory('/deicingReportExport');
    HotRequestExport = this.apiFactory.resourceFactory('/hotRequestExport');
    StationReadingGen2 = this.apiFactory.resourceFactory('/stationReadingGen2');
    StationReadingGen2RawData = this.apiFactory.resourceFactory('/stationReadingGen2RawData');
    StationReadingGen2Export = this.apiFactory.resourceFactory('/stationReadingGen2Export');
    StationReadingGen3 = this.apiFactory.resourceFactory('/stationReadingGen3');
    StationReadingGen3Export = this.apiFactory.resourceFactory('/stationReadingGen3Export');
    StationReadingGen3RawData = this.apiFactory.resourceFactory('/stationReadingGen3RawData');
    StationReadingGen3CsvData = this.apiFactory.resourceFactory('/stationReadingGen3CsvData');
    StationReadingGenV = this.apiFactory.resourceFactory('/stationReadingGenV');
    StationReadingGenVExport = this.apiFactory.resourceFactory('/stationReadingGenVExport');
    WeatherProfileExport = this.apiFactory.resourceFactory('/weatherProfileExport');
    MetarReading = this.apiFactory.resourceFactory('/metarReading');
    MetarReadingExport = this.apiFactory.resourceFactory('/metarReadingExport');
    MetarSnapshot = this.apiFactory.resourceFactory('/metarSnapshot');
    BarrelIcingMonitoringData = this.apiFactory.resourceFactory('/barrelIcingMonitoringData');
    BarrelIcingEmail = this.apiFactory.resourceFactory('/barrelIcingEmail');
    BarrelIcingExport = this.apiFactory.resourceFactory('/BarrelIcingExport');
    BarrelIcingManual = this.apiFactory.resourceFactory('/barrelIcingManual');
    ActiveFrostMonitoringData = this.apiFactory.resourceFactory('/activeFrostMonitoringData');
    ActiveFrostEmail = this.apiFactory.resourceFactory('/activeFrostEmail');
    ActiveFrostExport = this.apiFactory.resourceFactory('/activeFrostExport');
    ActiveFrostManual = this.apiFactory.resourceFactory('/activeFrostManual');
    NoaaRapReport = this.apiFactory.resourceFactory('/noaaRapReport');
    NoaaRapReportExport = this.apiFactory.resourceFactory('/noaaRapReportExport');
    EngineCoverAlert = this.apiFactory.resourceFactory('/engineCoverAlert');
    EngineCoverAlertExport = this.apiFactory.resourceFactory('/engineCoverAlertExport');
    EngineCoverManualReset = this.apiFactory.resourceFactory('/engineCoverManualReset');
    NoaaWpcForecast = this.apiFactory.resourceFactory('/noaaWpcForecast');
    NoaaWpcForecastExport = this.apiFactory.resourceFactory('/noaaWpcForecastExport');
    OpenMeteoForecast = this.apiFactory.resourceFactory('/openMeteoForecast');
    OpenMeteoForecastExport = this.apiFactory.resourceFactory('/openMeteoForecastExport');
    StationMonitoringActionType = this.apiFactory.enumResourceFactory('/StationMonitoringActionType');
    NoaaWpcAlert = this.apiFactory.resourceFactory('/noaaWpcAlert');
    NoaaWpcAlertEmail = this.apiFactory.resourceFactory('/noaaWpcAlertEmail');
    HeatFlapsAlertProfile = this.apiFactory.resourceFactory('/HeatFlapsAlertProfile');
    HeatFlapsAlert = this.apiFactory.resourceFactory('/HeatFlapsAlert');
    HeatFlapsAlertExport = this.apiFactory.resourceFactory('/HeatFlapsAlertExport');
    FlapContaminationAlertProfile = this.apiFactory.resourceFactory('/FlapContaminationAlertProfile');
    FlapContaminationAlert = this.apiFactory.resourceFactory('/FlapContaminationAlert');
    FlapContaminationAlertExport = this.apiFactory.resourceFactory('/FlapContaminationAlertExport');
    FreezingFogAlertProfile = this.apiFactory.resourceFactory('/FreezingFogAlertProfile');
    FreezingFogAlert = this.apiFactory.resourceFactory('/FreezingFogAlert');
    FreezingFogAlertExport = this.apiFactory.resourceFactory('/FreezingFogAlertExport');
    FreezingFogAlertAirplane = this.apiFactory.resourceFactory('/FreezingFogAlertAirplane');
    VoiceCallerTest = this.apiFactory.resourceFactory('/voiceCallerTest');
    HumanObservation = this.apiFactory.resourceFactory('/humanObservation');
    HumanObservationExport = this.apiFactory.resourceFactory('/humanObservationExport');
    Version = this.apiFactory.resourceFactory('/version');
    AcarsOooiReport = this.apiFactory.resourceFactory('/acarsOooiReport');
    AcarsOooiReportExport = this.apiFactory.resourceFactory('/acarsOooiReportExport');
    FlairOooiReport = this.apiFactory.resourceFactory('/flairOooiReport');
    FlairOooiReportExport = this.apiFactory.resourceFactory('/flairOooiReportExport');
    Environment = this.apiFactory.resourceFactory('/environment');
    EngineCoverAlertProfile = this.apiFactory.resourceFactory('/EngineCoverAlertProfile');
    ActiveFrostProfile = this.apiFactory.resourceFactory('/ActiveFrostProfile');
    BarrelIcingProfile = this.apiFactory.resourceFactory('/BarrelIcingProfile');
    AidxEvent = this.apiFactory.resourceFactory('/AidxEvent');
    AidxEventExport = this.apiFactory.resourceFactory('/AidxEventExport');
    StationPerformance = this.apiFactory.resourceFactory('/StationPerformance');
    StationPerformanceExport = this.apiFactory.resourceFactory('/StationPerformanceExport');
    DeicingAirline = this.apiFactory.resourceFactory<any>('/DeicingAirline');
    FlightAwareFirehoseMessage = this.apiFactory.resourceFactory('/FlightAwareFirehoseMessage');
    FlightAwareFirehoseMessageExport = this.apiFactory.resourceFactory('/FlightAwareFirehoseMessageExport');
    FinnairInit = this.apiFactory.resourceFactory('/FinnairInit');
    FinnairInitExport = this.apiFactory.resourceFactory('/FinnairInitExport');
    SureForecastAllClear = this.apiFactory.resourceFactory('/SureForecastAllClear');
    SureForecastAllClearExport = this.apiFactory.resourceFactory('/SureForecastAllClearExport');
    NowcastsLWE12 = this.apiFactory.resourceFactory('/NowcastsLWE12');
    NowcastsLWE12Export = this.apiFactory.resourceFactory('/NowcastsLWE12Export');
    NowcastsLWE30 = this.apiFactory.resourceFactory('/NowcastsLWE30');
    NowcastsLWE30Export = this.apiFactory.resourceFactory('/NowcastsLWE30Export');
    DeicingRequest = this.apiFactory.resourceFactory('/DeicingRequest');
    DeicingRequestExport = this.apiFactory.resourceFactory('/DeicingRequestExport');
    AcarsIncomingMessage = this.apiFactory.resourceFactory('/AcarsIncomingMessage');
    AcarsIncomingMessageExport = this.apiFactory.resourceFactory('/AcarsIncomingMessageExport');
    AcarsOutgoingMessage = this.apiFactory.resourceFactory('/AcarsOutgoingMessage');
    AcarsOutgoingMessageExport = this.apiFactory.resourceFactory('/AcarsOutgoingMessageExport');
    Flight = this.apiFactory.resourceFactory('/Flight');
    FlightExport = this.apiFactory.resourceFactory('/FlightExport');
    PortalUser = this.apiFactory.resourceFactory('/PortalUser');
    PortalUserLogin = this.apiFactory.resourceFactory('/PortalUserLogin');
    PortalUserLoginExport = this.apiFactory.resourceFactory('/PortalUserLoginExport');
    SartoriusSetting = this.apiFactory.resourceFactory('/SartoriusSetting');
    IMAPServerConfiguration = this.apiFactory.resourceFactory('/IMAPServerConfiguration');
    JMSServerConfiguration = this.apiFactory.resourceFactory('/JMSServerConfiguration');
    SQSQueueConfiguration = this.apiFactory.resourceFactory('/SQSQueueConfiguration');
    DeicingServiceProvider = this.apiFactory.resourceFactory('/DeicingServiceProvider');
    SFTPServerConfiguration = this.apiFactory.resourceFactory('/SFTPServerConfiguration');
    MobileDocumentProfile = this.apiFactory.resourceFactory<any>('/mobileDocumentProfile');
    MobileDocumentProfileExport = this.apiFactory.resourceFactory('/mobileDocumentProfileExport');
    FluidProfile = this.apiFactory.resourceFactory<any>('/fluidProfile');
    DeicingFluid = this.apiFactory.resourceFactory('/deicingFluid');
    HoldoverTaxiTimeModelConfiguration = this.apiFactory.resourceFactory('/holdoverTaxiTimeModelConfiguration');
    HoldoverTaxiTimeModelConfigurationExport = this.apiFactory.resourceFactory('/holdoverTaxiTimeModelConfigurationExport');
    ClientFluidNoteExport = this.apiFactory.resourceFactory('/clientFluidNoteExport');
    ClientFluidExport = this.apiFactory.resourceFactory('/clientFluidExport');
    ClientErrorExport = this.apiFactory.resourceFactory('/clientErrorExport');
    ClientContactExport = this.apiFactory.resourceFactory('/clientContactExport');
    ClientMessageMappingExport = this.apiFactory.resourceFactory('/clientMessageMappingExport');
    ClientFleetExport = this.apiFactory.resourceFactory('/clientFleetExport');
    ClientPortalUserExport = this.apiFactory.resourceFactory('/clientPortalUserExport');
    TableauUser = this.apiFactory.resourceFactory('/TableauUser');
    ClientSaml2ServiceProviderMetadata = this.apiFactory.resourceFactory('/clientSaml2ServiceProviderMetadata');
    ClientSaml2IdentityProviderMetadata = this.apiFactory.resourceFactory('/clientSaml2IdentityProviderMetadata');
    ClientMobileConfigDownload = this.apiFactory.resourceFactory('/ClientMobileConfigDownload');
    WeatherAlertProfile = this.apiFactory.resourceFactory<any>('/WeatherAlertProfile');
    WeatherAlert = this.apiFactory.resourceFactory('/WeatherAlert');
    WeatherAlertExport = this.apiFactory.resourceFactory('/WeatherAlertExport');
    WeatherAlertManualReset = this.apiFactory.resourceFactory('/WeatherAlertManualReset');
    EmailFrom = this.apiFactory.resourceFactory('/EmailFrom');
    YvrWeatherHot = this.apiFactory.resourceFactory('/YvrWeatherHot');
    YvrWeatherHotExport = this.apiFactory.resourceFactory('/YvrWeatherHotExport');
}
